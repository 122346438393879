import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl: string = '/espace-client';
  token: string|null = localStorage.getItem('api.token');

  constructor() { }

  isLoggedIn(): boolean {
    if (this.token) {
      try {
        var jwt = JSON.parse(atob(this.token.split('.')[1]));
        var date = Math.floor(Date.now() / 1000);
        return date < jwt.exp;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  setToken(token: string): void {
    this.token = token;
    localStorage.setItem('api.token', token);
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('api.token');
  }

}
