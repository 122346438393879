import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDjCEZsujPREom5ast-ynx2hH3Yxp9Q8Tc",
  authDomain: "domusign.firebaseapp.com",
  projectId: "domusign",
  storageBucket: "domusign.appspot.com",
  messagingSenderId: "349578937697",
  appId: "1:349578937697:web:e5bbdb2505c913ae85f120",
  measurementId: "G-M4XLQ5WXMY"
};

const app = initializeApp(firebaseConfig);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
