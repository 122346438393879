import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { environment } from "../environments/environment";
import { AuthGuard } from "./guards/auth.guard";

let routes: Routes = [];

if (environment.pdf) {
  routes = [
    { path: '', loadChildren: () => import('./modules/pdf/pdf.module').then(m => m.PdfModule) },
  ];
}

if (environment.web) {
  routes = [
    { path: 'espace-client', loadChildren: () => import('./modules/domusign/domusign.module').then(m => m.DomusignModule), canActivate: [ AuthGuard ] },
    { path: '', loadChildren: () => import('./modules/guest/guest.module').then(m => m.GuestModule) },
  ];
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
