import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { ApiService } from "./services/api.service";
import { Router, NavigationStart } from "@angular/router";
import { OnlineStatusService, OnlineStatusType } from "ngx-online-status";

import { fetchAndActivate, getRemoteConfig, getString, getBoolean } from "firebase/remote-config";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'domusign-web';

  onlineStatus: OnlineStatusType = this.onlineStatusService.getStatus();
  onlineStatusType: any = OnlineStatusType;
  isServerOk: boolean = true;
  refreshing = false;
  maintenance: boolean = false;
  motd: boolean = false;
  lang: string = "fr";
  maintenance_msg: any;
  motd_msg: any;

  remoteConfig = getRemoteConfig();
  
  ngOnInit(): void {
    this.apiService.post('', {}, (res: any) => {
      this.isServerOk = true;
    }, (res) => {
      this.isServerOk = false;
    });
  }

  constructor(translate: TranslateService, private apiService: ApiService, private router: Router, private onlineStatusService: OnlineStatusService) {

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.checkConnection();
        this.getRemoteConfig();
      }
    });

    onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      this.onlineStatus = status;
    });

    var userLang = navigator.language;
    translate.addLangs(environment.langs);
    translate.setDefaultLang(environment.langs[0]);

    if (userLang && userLang.length >= 2) {
      userLang = userLang.slice(0, 2)
    }

    if (localStorage.getItem("language")) {
      userLang = localStorage.getItem("language");
    }

    if (environment.langs.includes(userLang)) {
      this.lang = userLang;
      translate.use(userLang);
      localStorage.setItem("language", userLang);
    } else {
      this.lang = "fr";
      translate.use("fr");
      localStorage.setItem("language", "fr");
    }

  }

  checkConnection() {
    this.apiService.post("", {}, (res: any) => {
      this.isServerOk = true;
      this.refreshing = false;
    }, (res) => {
      this.isServerOk = false;
      this.refreshing = false;
    });
  }

  getRemoteConfig() {
    this.remoteConfig.settings.minimumFetchIntervalMillis = 30000;
    fetchAndActivate(this.remoteConfig)
      .then(() => {
        this.maintenance = getBoolean(this.remoteConfig, "maintenance");
        this.maintenance_msg = JSON.parse(getString(this.remoteConfig, "maintenance_msg"));

        this.motd = getBoolean(this.remoteConfig, "motd");
        this.motd_msg = JSON.parse(getString(this.remoteConfig, "motd_msg"));
      })
      .catch((err) => {
      
      });
  }

  handleMotdButton() {
    if (this.motd_msg.btn_link) {
      window.open(this.motd_msg.btn_link, "_blank");
    }
  }

  refresh() {
    this.refreshing = true;
    this.checkConnection();
  }
}
