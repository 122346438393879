export const environment = {
  production: false,
  api: {
    url: 'https://apidev.domusign.com',
  },
  document: {
    url: 'https://apidev.domusign.com/document',
  },
  home: {
    url: 'https://dev.domusign.com',
  },
  pricing: [
    {
      name: "Pack 10",
      price: 11.99,
      credits: 10
    },
    {
      name: "Pack 25",
      price: 24.99,
      credits: 25
    },
    {
      name: "Pack 50",
      price: 39.99,
      credits: 50
    }
  ],
  web: true,
  pdf: false,
  langs: ["en", "fr"],
  adobe_pdf_viewer: {
    client_id: 'e56a07707c0f43b28a005e8a0f392fd4',//dev.domusign.com
    //client_id: '2e44f95fde8a47e08ef05831f0d7a692',//localhost
  }
};
