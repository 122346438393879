<div class="row" *ngIf="motd && motd_msg.text && onlineStatus === onlineStatusType.ONLINE && isServerOk && !maintenance">
  <div class="motd-container">
    <span class="motd-text">{{motd_msg.text}}</span>
    <span *ngIf="motd_msg.btn_text && motd_msg.btn_link" class="motd-text-link" (click)="handleMotdButton()">{{motd_msg.btn_text}}</span>
  </div>
</div>

<router-outlet *ngIf="onlineStatus === onlineStatusType.ONLINE && isServerOk && !maintenance"></router-outlet>

<div class="row h-100" *ngIf="onlineStatus === onlineStatusType.OFFLINE || !isServerOk || maintenance">
  <div class="col-10 offset-1 offset-lg-3 col-lg-6 card my-auto py-5">
    <div *ngIf="onlineStatus === onlineStatusType.OFFLINE">
      <div class="text-center">
        <span class="offline-title" translate="offline.offline-title"></span><br>
        <span class="offline-text" translate="offline.offline-text"></span><br>
      </div>
    </div>

    <div *ngIf="!isServerOk && onlineStatus === onlineStatusType.ONLINE && !maintenance">
      <div class="text-center">
        <img src="assets/header.png" height="64"><br><br>
        <span class="offline-title" translate="offline.offline-server-title"></span><br>
        <span class="offline-text" translate="offline.offline-server-text"></span><br>
        <button class="btn btn-primary offline-refresh-button btn-domusign-green mt-5" (click)="refresh()">
          <span *ngIf="refreshing" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
          <span translate="offline.offline-server-retry-button"></span>
        </button>
      </div>
    </div>

    <div *ngIf="maintenance && onlineStatus === onlineStatusType.ONLINE" class="row">
      <div class="text-center">
        <img src="assets/header.png" height="64"><br><br>
        <span class="text-center col-12 mt-4 offline-title" translate="maintenance.maintenance-title"></span><br>
        <span class="text-center col-12 mt-2 offline-text">{{maintenance_msg[lang]}}</span>
      </div>
    </div>
  </div>
</div>
