import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  base_url = environment.api.url;

  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {}

  post(service: string, params: any, success: ((res: any) => void)|null = null, fail: ((res: ErrorResponse) => void)|null = null): void {
    let headers: any = {};
    if (this.authService.isLoggedIn() && environment.web) {
      headers['Authorization'] = 'Bearer ' + this.authService.token;
    }

    if (!params) {
      params = {};
    }
    params.__url__ = location.toString();
    params.source = 'web';

    this.http.post(this.base_url + '/' + service, params, {
      headers: new HttpHeaders(headers)
    }).subscribe(
      (res: any) => this.handleResponse(res, success, fail),
      (error: HttpErrorResponse) => this.handleResponse(error, success, fail)
    );
  }

  handleResponse(res: Response|HttpErrorResponse, success: ((res: any) => void)|null = null, fail: ((res: ErrorResponse) => void)|null = null) {
    if (res instanceof HttpErrorResponse) {
      if (res.error) {
        res = res.error;
      }
    }

    if ((res as Response).success) {
      if (success) success(res);
    } else {
      if (fail) fail(res as ErrorResponse);
      if ((res as ErrorResponse).error === 401) {
        this.authService.logout();
        this.router.navigate(['/connexion']);
      }
    }
  }

  convertToFormData(data: any, formData: FormData, parentKey: string|null) {
    if(data === null || data === undefined) return null;

    formData = formData || new FormData();

    if (typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key =>
        this.convertToFormData(data[key], formData, (!parentKey ? key : (data[key] instanceof File ? parentKey : `${parentKey}[${key}]`)))
      );
    } else {
      formData.append(parentKey!, data);
    }

    return formData;
  }

  upload(service: string, params: any, files: any, progress: ((progress: number) => void)|null = null, success: ((res: any) => void)|null = null, fail: ((res: ErrorResponse) => void)|null = null): void {
    var formData = new FormData();
    formData.set('source', 'web');

    this.convertToFormData(params, formData, null);

    if (files instanceof FileList) {
      for (var i = 0; i < files.length; i++) {
        formData.append('files[]', files.item(i)!, files.item(i)!.name);
      }
    }
    if (files instanceof File) {
      formData.append('files[]', files, files.name);
    }

    if (files instanceof Blob) {
      formData.append('files[]', files, params['file']);
    }

    let headers: any = {};
    if (this.authService.isLoggedIn() && environment.web) {
      headers['Authorization'] = 'Bearer ' + this.authService.token;
    }

    this.http.request(new HttpRequest('POST', this.base_url + '/' + service, formData, {
      reportProgress: true,
      headers: new HttpHeaders(headers),
    })).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          if (progress) progress(0);
          break;
        case HttpEventType.UploadProgress:
          const percentDone = Math.round(100 * event.loaded / event.total!);
          if (progress) progress(percentDone);
          break;
        case HttpEventType.Response:
          let res = event.body;

          if (res.success) {
            if (success) success(res);
          } else {
            if (fail) fail(res as ErrorResponse);
          }
          break;
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        let res = err.error;

        if (res.success) {
          if (success) success(res);
        } else {
          if (fail) fail(res as ErrorResponse);
        }
      }
    });
  }

  logout(callback: () => void) {
    this.post('logout', {}, res => {
      this.authService.logout();
      callback();
    }, res => {
      this.authService.logout();
      callback();
    });
  }
}

export interface Response {
  success: boolean;
}

export interface ErrorResponse extends Response {
  error: number;
  message: string|null
}

export interface LoginResponse extends Response{
  token: string;
  user: any;
  lang: string;
}

export interface RegistrationResponse extends Response {
  status: string;
  token: string;
  user: any;
}

export interface DocumentUploadResponse extends Response {
  document: any;
  uploads: any[];
}

export interface DocumentListResponse extends Response {
  documents: any[];
}

export interface DocumentListPageResponse extends Response {
  success: boolean;
  count: number;
  pages: number;
  list: any[];
}
